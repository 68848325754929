.demo-style {
	.container-fluid {
		max-width: 1600px;
	}
	.demo-header {
		background-color: #163a0f;
		z-index: 10;
		@include media-breakpoint-down(lg) {
			position: relative;
		}
		.logo {
			width: 125px;
			@include media-breakpoint-down(lg) {width: 100px;}
		}
	}
	.demo-banner {
		height: 95vh;
		background-color: #163a0f;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		@include media-breakpoint-down(lg) {
			height: auto !important;
			padding-bottom: 50px;
			margin-bottom: 40px;

		}
		.container {
			z-index: 4;
			position: relative;
		}
		&:after {
			content: '';
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background-image: url(../../img/demo-banner.jpg);
			background-size: 100%;
		}
		.content-text {
			padding-top: 120px;
			@include media-breakpoint-down(lg) {
				padding-top: 60px;
			}
		}
	}
	.navbar-nav {
		text-align: center;
		width: 100%;
		display: block;
		padding-top:8px;
		li {
			list-style-type: none;
			display: inline-block;
			padding-right: 10px;
			padding-left: 10px;
			@include media-breakpoint-down(md) {
				width: 100%;
				text-align: left;
			}
			a {
				display: inline-block;
				font-size: 11px;
				font-weight: 600;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #fff;
			}
		}
	}
	h1 {
		font-size: 45px;
		letter-spacing: 0;
		font-weight: 700;
		line-height: 1.3;
		@include media-breakpoint-down(xl) {
			font-size: 32px !important;
			line-height: 1.2;
		}

	}
	h2 {
		font-size: 36px;
		letter-spacing: 0;
		line-height: 1.3;
		color: green;
		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 1.2;
		}
	}

	h5 {
		font-size: 18px;
		letter-spacing: 0;
		line-height: 1.3;
		font-weight: 700;
		@include media-breakpoint-down(md) {
			font-size: 16px;
			line-height: 1.2;
		}
	}

	p {
		font-size: 14px;
		line-height:26px;
		margin-top: 10px;
		@include media-breakpoint-down(xl) {
			line-height: 20px;
			font-size: 12px;
		}
	}
	.nav-tabs .nav-link {
		border:2px green solid;
		color: green;
		font-size: 13px;
		font-weight: 600;
		background-color:transparent;
		border-radius: 25px;
		padding:10px 30px; 
		&.active {
			background-color: green;
			color: #fff;
		}
		@include media-breakpoint-down(sm) {
			font-size: 13px;
			padding:5px 0px; 
			border:0;
			
			border-radius: 0;
			&.active {
				background-color: transparent;
				color: #000;
				border-bottom: 2px solid #000;
			}
		}
	}
	.demo-item {
		overflow: hidden;
		img {transition: all 0.4s ease; position: relative;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-top: 0; margin-bottom: 10px;}
		&:hover img  {
			position: relative;
			margin-top: 10px;
			margin-bottom: 0;
			-webkit-filter: blur(3px);
    		filter: blur(3px);
		}
		&:hover .btn-wrap {
		    top: 45%;
		}
		.btn-wrap {
		    top: -100px;
		    transition: all 0.4s ease;
		    width: 100%;
		    left: 0;
		    position: absolute;
		    text-align: center;
		     a {
			    width: 150px;
			    background-color: #222;
			    color: #fff;
			    font-size: 15px;
			    font-weight: 600;
			    line-height: 50px;
			    height: 50px;
			    display: inline-block;
			    margin: 4px;
			    letter-spacing: 2px;
			    border-radius: 5px;
			    font-size: 13px;
			}
		}
	}
}