@charset "UTF-8";
/*-----------------------------------------------------------
    Theme Name: Orgomart
    Theme URI: http://uitheme.net/orgomart/
    Author: Uttam D
    Author URI: http://uitheme.net/
    Description: Online Food Ordering HTML Template
    Version: 1.0.0
-------------------------------------------------------------*/
/*-----------------------------------------------------------
    Table of Content: 



    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Component
    5. Pages
    7. Header
    8. Footer


*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');
@import '././vendor/icon/feather.css';
@import '././vendor/icon/themify-icons.css';

.theme-dark {
  background-color: #1a2236;
}

.bg-facebook {
  background-color: #0d66ff !important;
}

.bg-messenger {
  background-color: #55acee !important;
}

.bg-twiiter {
  background-color: #3b5999 !important;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

.bg-skype {
  background-color: #00aff0 !important;
}

.bg-pinterest {
  background-color: #bd081c !important;
}

.bg-instagram {
  background-color: #e4405f !important;
}

.bg-flicker {
  background-color: #ff0084 !important;
}

.bg-whatsup {
  background-color: #25d366 !important;
}

.bg-tumblr {
  background-color: #34465d !important;
}

.bg-youtube {
  background-color: #cd201f !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-facebook {
  color: #0d66ff !important;
}

.text-messenger {
  color: #55acee !important;
}

.text-twiiter {
  color: #3b5999 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-skype {
  color: #00aff0 !important;
}

.text-pinterest {
  color: #bd081c !important;
}

.text-instagram {
  color: #e4405f !important;
}

.text-current {
  color: var(--theme-color) !important;
}

.text-red {
  color: red !important;
}

.text-ornage {
  color: #ff9500 !important;
}

.text-black {
  color: #000 !important;
}

.text-cyan {
  color: #00a9a8 !important;
}

.text-yellow {
  color: #ffde00 !important;
}

.bg-black-08 {
  background: rgba(0, 0, 0, 0.8) !important;
}

.bg-theme-dark {
  background-color: #1a2236 !important;
}

.theme-black-bg,
.theme-bg {
  background-color: #eee !important;
}

.bg-current {
  background-color: var(--theme-color) !important;
}

.bg-current-shade {
  background-color: var(--theme-color-rgb) !important;
}

.bg-current-gradiant {
  background: linear-gradient(135deg,
      var(--theme-color),
      var(--theme-color-shade)) !important;
}

.bg-primary-gradiant {
  background: linear-gradient(135deg, #05f, #09f) !important;
}

.bg-gold-gradiant {
  background: linear-gradient(to right, #f2994a, #f2c94c) !important;
}

.bg-red-gradiant {
  background: linear-gradient(to right, #e44d26, #f16529) !important;
}

.bg-mini-gradiant {
  background: linear-gradient(to right, #ee0979, #ff6a00) !important;
}

.bg-blue-gradiant {
  background: linear-gradient(to right, #0575e6, #021b79) !important;
}

.bg-gradiant-1 {
  background: linear-gradient(180deg, #e5edbe, #bdcd8c) !important;
}

.bg-gradiant-2 {
  background: linear-gradient(180deg, #cfebd2, #a9ccac) !important;
}

.bg-gradiant-3 {
  background: linear-gradient(180deg, #d3eaf2, #9ccbd1) !important;
}

.bg-gradiant-4 {
  background: linear-gradient(180deg, #fad0a8, #d7b58e) !important;
}

.bg-lightbrown2 {
  background-color: #f7ede1;
}

.bg-grey {
  background-color: #eee !important;
}

.bg-darkgrey {
  background-color: #555 !important;
}

.bg-lightblue {
  background-color: #e8f1fa !important;
}

.bg-lightblue2 {
  background-color: #f7f9fd !important;
}

.bg-lightgreen {
  background-color: #f2faf6 !important;
}

.bg-greyblue {
  background-color: #f6f7fc !important;
}

.bg-greylight {
  background-color: #f5f5f5 !important;
}

.bg-lightgrey {
  background-color: #f9f9f9 !important;
}

.bg-lightgrey2 {
  background-color: #fbfcfe !important;
}

.bg-lightbrown {
  background-color: #fdfbf4 !important;
}

.bg-orange {
  background-color: #ff9500 !important;
}

.bg-cyan {
  background-color: #00a9a8 !important;
}

.bg-yellow {
  background-color: #ffde00 !important;
}

.bg-green {
  background-color: #27901a;
}

.bg-deepgreen {
  background-color: #12670a;
}

.bg-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-blur-after:after {
  content: '';
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.bg-shade-after:after {
  content: '';
  background: var(--theme-color-rgb) !important;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-transparet:after {
  content: '';
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-transparet-6:after {
  content: '';
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-bottom:after {
  content: '';
  background: linear-gradient(to top, #000, transparent);
  width: 100%;
  height: 400px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-top:before {
  content: '';
  background: linear-gradient(to bottom, #000, transparent);
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-right:after {
  content: '';
  background: linear-gradient(to left, #000, transparent);
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.color-theme-gold {
  --theme-color: #8f7646;
  --theme-color-rgb: 255, 59, 48;
  --theme-color-shade: #fb9f81;
  --theme-color-tint: #ff6259;
}

.color-theme-red {
  --theme-color: #f32323;
  --theme-color-rgb: 255, 59, 48;
  --theme-color-shade: #fb9f81;
  --theme-color-tint: #ff6259;
}

.color-theme-green {
  --theme-color: #1ca301;
  --theme-color-rgb: rgba(76, 217, 100, 0.08);
  --theme-color-shade: #1ca301;
  --theme-color-tint: #1ca301;
}

.color-theme-blue {
  --theme-color: #05f;
  --theme-color-rgb: rgba(33, 150, 243, 0.08);
  --theme-color-shade: #09f;
  --theme-color-tint: #1e74fd;
}

.color-theme-deepblue {
  --theme-color: #0f36c0;
  --theme-color-rgb: rgba(33, 150, 243, 0.08);
  --theme-color-shade: #103ace;
  --theme-color-tint: #1e74fd;
}

.color-theme-pink {
  --theme-color: #ff2d55;
  --theme-color-rgb: rgba(255, 45, 85, 0.1);
  --theme-color-shade: #ff0434;
  --theme-color-tint: #ff5676;
}

.color-theme-yellow {
  --theme-color: #fbaf03;
  --theme-color-rgb: rgba(255, 204, 0, 0.1);
  --theme-color-shade: #d6ab00;
  --theme-color-tint: #ffd429;
}

.color-theme-orange {
  --theme-color: #ff9500;
  --theme-color-rgb: rgba(255, 149, 0, 0.1);
  --theme-color-shade: #d67d00;
  --theme-color-tint: #ffa629;
}

.color-theme-purple {
  --theme-color: #9c27b0;
  --theme-color-rgb: rgba(156, 39, 176, 0.1);
  --theme-color-shade: #7e208f;
  --theme-color-tint: #b92fd1;
}

.color-theme-deeppurple {
  --theme-color: #673ab7;
  --theme-color-rgb: rgba(103, 58, 183, 0.1);
  --theme-color-shade: #563098;
  --theme-color-tint: #7c52c8;
}

.color-theme-lightblue {
  --theme-color: #346e93;
  --theme-color-rgb: rgba(90, 200, 250, 0.1);
  --theme-color-shade: #7ac1ed;
  --theme-color-tint: #82d5fb;
}

.color-theme-teal {
  --theme-color: #00bfad;
  --theme-color-rgb: rgba(0, 150, 136, 0.1);
  --theme-color-shade: #00bfad;
  --theme-color-tint: #00bfad;
}

.color-theme-lime {
  --theme-color: #cddc39;
  --theme-color-rgb: rgba(205, 220, 57, 0.1);
  --theme-color-shade: #bac923;
  --theme-color-tint: #d6e25c;
}

.color-theme-deeporange {
  --theme-color: #ff6b22;
  --theme-color-rgb: rgba(255, 107, 34, 0.1);
  --theme-color-shade: #f85200;
  --theme-color-tint: #ff864b;
}

.color-theme-gray {
  --theme-color: #8e8e93;
  --theme-color-rgb: rgba(142, 142, 147, 0.1);
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7;
}

.color-theme-white {
  --theme-color: #ffffff;
  --theme-color-rgb: rgba(255, 255, 255, 0.1);
  --theme-color-shade: #ebebeb;
  --theme-color-tint: #ffffff;
}

.color-theme-black {
  --theme-color: #000000;
  --theme-color-rgb: rgba(0, 0, 0, 0.1);
  --theme-color-shade: #000000;
  --theme-color-tint: #141414;
}

.color-theme-brown {
  --theme-color: #f2ece5;
  --theme-color-rgb: rgba(#f6f2ec, 0.1);
  --theme-color-shade: #a27761;
  --theme-color-tint: #e4d8ca;
}

.color-theme-darkgreen {
  --theme-color: #1c891f;
  --theme-color-rgb: rgba(142, 142, 147, 0.1);
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7;
}

.color-theme-cayan {
  --theme-color: #7ac1ed;
  --theme-color-rgb: rgba(255, 255, 255, 0.1);
  --theme-color-shade: #6ae2da;
  --theme-color-tint: #6ae2da;
}

.color-theme-darkorchid {
  --theme-color: #9932cc;
  --theme-color-rgb: rgba(0, 0, 0, 0.1);
  --theme-color-shade: #000000;
  --theme-color-tint: #141414;
}

.color-theme-black {
  --theme-color: #000;
  --theme-color-rgb: rgba(0, 0, 0, 0.1);
  --theme-color-shade: #000;
  --theme-color-tint: #000;
}

.color-theme-cadetblue {
  --theme-color: #5f9ea0;
  --theme-color-rgb: rgba(0, 0, 0, 0.1);
  --theme-color-shade: #5f9ea0;
  --theme-color-tint: #5f9ea0;
}

.color-theme-deeppink {
  --theme-color: #ffc0cb;
  --theme-color-rgb: rgba(0, 0, 0, 0.1);
  --theme-color-shade: #ffc0cb;
  --theme-color-tint: #ffc0cb;
}

.mont-font {
  font-family: 'Montserrat', sans-serif;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.open-font {
  font-family: 'Open Sans', sans-serif;
}

.btn-round-xss {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center;
}

.btn-round-xss i {
  line-height: 25px !important;
}

.btn-round-xs {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center;
}

.btn-round-xs i {
  line-height: 25px !important;
}

.btn-round-sm {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-block;
  line-height: 35px !important;
  text-align: center;
}

.btn-round-sm i {
  line-height: 35px !important;
}

.btn-round-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
}

.btn-round-md i {
  line-height: 45px;
}

.btn-round-lg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
}

.btn-round-lg i {
  line-height: 50px;
}

.btn-round-xl {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}

.btn-round-xl i {
  line-height: 60px;
}

.btn-round-xl img {
  line-height: 60px;
}

.btn-round-xxl {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  display: inline-block;
  line-height: 70px;
  text-align: center;
}

.btn-round-xxl i {
  line-height: 70px;
}

.btn-round-xxxl {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  line-height: 80px;
  text-align: center;
}

.btn-round-xxxl i {
  line-height: 80px;
}

.btn-round-xxxxl {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  display: inline-block;
  line-height: 110px;
  text-align: center;
}

.btn-round-xxxxl i {
  line-height: 110px;
}

.z-index-1 {
  z-index: 2;
  position: relative;
}

.z-index-5 {
  z-index: 5;
  position: relative;
}

.z-index-10 {
  z-index: 10;
  position: relative;
}

.z-index-15 {
  z-index: 15;
  position: relative;
}

.badge-primary {
  background-color: #1e74fd;
}

.badge-secondary {
  background-color: #673bb7;
}

.badge-success {
  background-color: #10d876;
}

.badge-info {
  background-color: #2754e6;
}

.badge-warning {
  background-color: #fe9431;
}

.badge-danger {
  background-color: #e50202;
}

.badge-light {
  background-color: #f7f7f7;
}

.badge-dark {
  background-color: #343a40;
}

.ls-0 {
  letter-spacing: -0.4px;
}

.ls-1 {
  letter-spacing: 0.4px;
}

.ls-2 {
  letter-spacing: 0.6px;
}

.ls-3 {
  letter-spacing: 1px;
}

.ls-4 {
  letter-spacing: 1.2px;
}

.ls-5 {
  letter-spacing: 1.4px;
}

.ls-6 {
  letter-spacing: 2px;
}

.ls-10 {
  letter-spacing: 5px;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-2 {
  line-height: 1.2 !important;
}

.lh-3 {
  line-height: 1.4 !important;
}

.lh-4 {
  line-height: 1.6 !important;
}

.lh-5 {
  line-height: 2 !important;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-26 {
  line-height: 26px !important;
}

.lh-28 {
  line-height: 28px !important;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.mt-n10 {
  margin-top: -100px;
}

.mt-n7 {
  margin-top: -75px;
}

.ps-6 {
  padding-left: 34px !important;
}

.font-xssssss {
  font-size: 8px !important;
}

.font-xsssss {
  font-size: 10px !important;
}

.font-xssss {
  font-size: 12px !important;
}

.font-xsss {
  font-size: 14px !important;
}

.font-xss {
  font-size: 16px !important;
}

.font-xs {
  font-size: 18px !important;
}

.font-sm {
  font-size: 20px !important;
}

.font-md {
  font-size: 22px !important;
}

.font-lg {
  font-size: 25px !important;
}

.font-xl {
  font-size: 28px !important;
}

.font-xxl {
  font-size: 32px !important;
}

.display1-size {
  font-size: 36px !important;
}

.display2-size {
  font-size: 44px !important;
}

.display3-size {
  font-size: 50px !important;
}

.display4-size {
  font-size: 60px !important;
}

.display5-size {
  font-size: 80px !important;
}

.shadow-md {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-xs {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}

.shadow-xss {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.border.border-size-sm {
  border-width: 1px !important;
}

.border.border-size-md {
  border-width: 2px !important;
}

.border.border-size-lg {
  border-width: 3px !important;
}

.border-light {
  border-color: #eee !important;
}

.border-dark {
  border-color: #999 !important;
}

.border-right-light {
  border-right: 1px #eee solid;
}

.border-bottom-light {
  border-bottom: 1px #eee solid;
}

.border-bottom-dark {
  border-bottom: 2px #333 solid;
}

.border-bottom-blue {
  border-bottom: 1px #1254a1 solid;
}

.border-dashed {
  border: 2px #ddd dashed !important;
}

.border-black {
  border: 2px #000 dashed !important;
}

.border-right-0.card {
  border-right: 0 !important;
}

.border-danger {
  border: 2px #e50202 solid;
}

.bg-image-cover {
  background-size: cover;
}

.bg-image-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-image-center {
  background-position: center center;
}

.bg-image-topcenter {
  background-position: top 30px center;
}

.bg-image-bottomcenter {
  background-position: bottom center !important;
}

.bg-image-right {
  background-position: center right;
}

.ovh {
  overflow: hidden;
}

.ovv {
  overflow: visible;
}

.posr {
  position: relative !important;
}

.posa {
  position: absolute;
}

.top-0 {
  top: 0px !important;
}

.top-15 {
  top: 15px !important;
}

.top-20 {
  top: 20px !important;
}

.top-25 {
  top: 25px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-25 {
  bottom: 25px !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
  content: '';
  display: block;
}

.embed-responsive-21by9::before {
  padding-top: 56.25%;
  content: '';
  display: block;
}

.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.main-wrapper.center-wrap {
  max-width: 1150px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(./img/preloader.gif);
  background-size: 70px;
}

.box {
  position: relative;
  width: 100%;
  height: 30vh;
  margin: 0px 0 60px 0;
  display: block;
  background: transparent;
}

.box .lines {
  position: absolute;
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box .line {
  position: relative;
  left: 0;
  width: 100%;
  margin: 0px 0 5px 0;
  border-radius: 5px;
}

.box .line:nth-child(2n) {
  height: calc(20% - 5px);
}

.box .line:nth-child(2n + 1) {
  height: calc(30% - 5px);
}

.box .s_shimmer {
  background: rgba(0, 0, 0, 0.08);
}

.box.shimmer {
  overflow: hidden;
}

.box.shimmer::after {
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0));
  animation: gradient 1600ms infinite;
  animation-timing-function: cubic-bezier(0.38, 0.55, 0.34, 0.95);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%) rotate(0deg);
  border-radius: 5px;
}

@keyframes gradient {
  100% {
    transform: translateX(100%) rotate(0deg);
  }
}

.feed-body {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}

.loaded .feed-body {
  visibility: visible;
  overflow: visible;
  height: 100%;
}

/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-primary: #1e74fd;
  --bs-secondary: #673bb7;
  --bs-success: #10d876;
  --bs-info: #2754e6;
  --bs-warning: #fe9431;
  --bs-danger: #e50202;
  --bs-light: #f7f7f7;
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #111;
}

h1,
.h1 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 1.5rem;
  }
}

h2,
.h2 {
  font-size: 1.25rem;
}

h3,
.h3 {
  font-size: 1.125rem;
}

h4,
.h4 {
  font-size: 1rem;
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1e74fd;
  text-decoration: underline;
}

a:hover {
  color: #185dca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #aeaed5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: '— ';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1100px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1400) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #515184;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #515184;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #515184;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #515184;
  vertical-align: top;
  border-color: #e1e1f0;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d2e3ff;
  --bs-table-striped-bg: #c8d8f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdcce6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2d2ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bdcce6;
}

.table-secondary {
  --bs-table-bg: #e1d8f1;
  --bs-table-striped-bg: #d6cde5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbc2d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0c8df;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbc2d9;
}

.table-success {
  --bs-table-bg: #cff7e4;
  --bs-table-striped-bg: #c5ebd9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badecd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe4d3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badecd;
}

.table-info {
  --bs-table-bg: #d4ddfa;
  --bs-table-striped-bg: #c9d2ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfc7e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4cce7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bfc7e1;
}

.table-warning {
  --bs-table-bg: #ffead6;
  --bs-table-striped-bg: #f2decb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d3c1;
}

.table-danger {
  --bs-table-bg: #facccc;
  --bs-table-striped-bg: #eec2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7bdbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1b8b8;
}

.table-light {
  --bs-table-bg: #f7f7f7;
  --bs-table-striped-bg: #ebebeb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dedede;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e4e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dedede;
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #aeaed5;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type='file'] {
  overflow: hidden;
}

.form-control[type='file']:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #515184;
  background-color: #fff;
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #515184;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #515184;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #515184;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #515184;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type='checkbox'] {
  border-radius: 0.25em;
}

.form-check-input[type='radio'] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.form-check-input:checked {
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type='checkbox']:indeterminate {
  background-color: #1e74fd;
  border-color: #1e74fd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238fbafe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1e74fd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #bcd5fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1e74fd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #bcd5fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #10d876;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(16, 216, 118, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #10d876;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #10d876;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #10d876;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #10d876;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #10d876;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e50202;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(229, 2, 2, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e50202;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #e50202;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #e50202;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #e50202;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #e50202;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #515184;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.btn-primary:hover {
  color: #000;
  background-color: #4089fd;
  border-color: #3582fd;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #4089fd;
  border-color: #3582fd;
  box-shadow: 0 0 0 0rem rgba(26, 99, 215, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #4b90fd;
  border-color: #3582fd;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(26, 99, 215, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.btn-secondary {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #58329c;
  border-color: #522f92;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #58329c;
  border-color: #522f92;
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #522f92;
  border-color: #4d2c89;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}

.btn-success {
  color: #000;
  background-color: #10d876;
  border-color: #10d876;
}

.btn-success:hover {
  color: #000;
  background-color: #34de8b;
  border-color: #28dc84;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #000;
  background-color: #34de8b;
  border-color: #28dc84;
  box-shadow: 0 0 0 0rem rgba(14, 184, 100, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #40e091;
  border-color: #28dc84;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(14, 184, 100, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #10d876;
  border-color: #10d876;
}

.btn-info {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}

.btn-info:hover {
  color: #fff;
  background-color: #2147c4;
  border-color: #1f43b8;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #2147c4;
  border-color: #1f43b8;
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1f43b8;
  border-color: #1d3fad;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}

.btn-warning {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431;
}

.btn-warning:hover {
  color: #000;
  background-color: #fea450;
  border-color: #fe9f46;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #fea450;
  border-color: #fe9f46;
  box-shadow: 0 0 0 0rem rgba(216, 126, 42, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fea95a;
  border-color: #fe9f46;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 126, 42, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431;
}

.btn-danger {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c30202;
  border-color: #b70202;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #c30202;
  border-color: #b70202;
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b70202;
  border-color: #ac0202;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}

.btn-light {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-light:hover {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  box-shadow: 0 0 0 0rem rgba(210, 210, 210, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9f9f9;
  border-color: #f8f8f8;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(210, 210, 210, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-primary {
  color: #1e74fd;
  border-color: #1e74fd;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #1e74fd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #673bb7;
  border-color: #673bb7;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #673bb7;
  background-color: transparent;
}

.btn-outline-success {
  color: #10d876;
  border-color: #10d876;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #10d876;
  border-color: #10d876;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #10d876;
  border-color: #10d876;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #10d876;
  background-color: transparent;
}

.btn-outline-info {
  color: #2754e6;
  border-color: #2754e6;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #2754e6;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fe9431;
  border-color: #fe9431;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fe9431;
  background-color: transparent;
}

.btn-outline-danger {
  color: #e50202;
  border-color: #e50202;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #e50202;
  background-color: transparent;
}

.btn-outline-light {
  color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0rem rgba(247, 247, 247, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(247, 247, 247, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f7f7f7;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1e74fd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #185dca;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1e74fd;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1e74fd;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #1e74fd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #185dca;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1e74fd;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.3rem 1.25rem;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #1b68e4;
  background-color: #e9f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231b68e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23515184'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, '/')
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1e74fd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #185dca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #185dca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: 5px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item .page-link {
  border-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item .page-link {
  border-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item .page-link {
  border-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #124698;
  background-color: #d2e3ff;
  border-color: #bcd5fe;
}

.alert-primary .alert-link {
  color: #0e387a;
}

.alert-secondary {
  color: #3e236e;
  background-color: #e1d8f1;
  border-color: #d1c4e9;
}

.alert-secondary .alert-link {
  color: #321c58;
}

.alert-success {
  color: #0e9955 !important;
  background-color: #dbffe5;
  border-color: #dbffe5;
}

.alert-success .alert-link {
  color: #054526;
}

.alert-info {
  color: #17328a;
  background-color: #d4ddfa;
  border-color: #beccf8;
}

.alert-info .alert-link {
  color: #12286e;
}

.alert-warning {
  color: #98591d;
  background-color: #ffead6;
  border-color: #ffdfc1;
}

.alert-warning .alert-link {
  color: #7a4717;
}

.alert-danger {
  color: #890101;
  background-color: #facccc;
  border-color: #f7b3b3;
}

.alert-danger .alert-link {
  color: #6e0101;
}

.alert-light {
  color: #636363;
  background-color: #fdfdfd;
  border-color: #fdfdfd;
}

.alert-light .alert-link {
  color: #4f4f4f;
}

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6;
}

.alert-dark .alert-link {
  color: #191c1e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1e74fd;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>li::before {
  content: counters(section, '.') '. ';
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #515184;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1e74fd;
  border-color: #1e74fd;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #124698;
  background-color: #d2e3ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #124698;
  background-color: #bdcce6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #124698;
  border-color: #124698;
}

.list-group-item-secondary {
  color: #3e236e;
  background-color: #e1d8f1;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3e236e;
  background-color: #cbc2d9;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3e236e;
  border-color: #3e236e;
}

.list-group-item-success {
  color: #06562f;
  background-color: #cff7e4;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #06562f;
  background-color: #badecd;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #06562f;
  border-color: #06562f;
}

.list-group-item-info {
  color: #17328a;
  background-color: #d4ddfa;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #17328a;
  background-color: #bfc7e1;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #17328a;
  border-color: #17328a;
}

.list-group-item-warning {
  color: #98591d;
  background-color: #ffead6;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #98591d;
  background-color: #e6d3c1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #98591d;
  border-color: #98591d;
}

.list-group-item-danger {
  color: #890101;
  background-color: #facccc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #890101;
  background-color: #e1b8b8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #890101;
  border-color: #890101;
}

.list-group-item-light {
  color: #636363;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636363;
  background-color: #e4e4e4;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636363;
  border-color: #636363;
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1f2326;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2326;
  border-color: #1f2326;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1f0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e1f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0
    /* rtl:ignore */
  ;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^='top']>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top']>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top']>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^='right']>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right']>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right']>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom']>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom']>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom']>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^='left']>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left']>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left']>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #111;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #515184;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.link-primary {
  color: #1e74fd;
}

.link-primary:hover,
.link-primary:focus {
  color: #4b90fd;
}

.link-secondary {
  color: #673bb7;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #522f92;
}

.link-success {
  color: #10d876;
}

.link-success:hover,
.link-success:focus {
  color: #40e091;
}

.link-info {
  color: #2754e6;
}

.link-info:hover,
.link-info:focus {
  color: #1f43b8;
}

.link-warning {
  color: #fe9431;
}

.link-warning:hover,
.link-warning:focus {
  color: #fea95a;
}

.link-danger {
  color: #e50202;
}

.link-danger:hover,
.link-danger:focus {
  color: #b70202;
}

.link-light {
  color: #f7f7f7;
}

.link-light:hover,
.link-light:focus {
  color: #f9f9f9;
}

.link-dark {
  color: #343a40;
}

.link-dark:hover,
.link-dark:focus {
  color: #2a2e33;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: '';
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e1e1f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e1e1f0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #e1e1f0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1f0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #e1e1f0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1e74fd !important;
}

.border-secondary {
  border-color: #673bb7 !important;
}

.border-success {
  border-color: #10d876 !important;
}

.border-info {
  border-color: #2754e6 !important;
}

.border-warning {
  border-color: #fe9431 !important;
}

.border-danger {
  border-color: #e50202 !important;
}

.border-light {
  border-color: #f7f7f7 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-2 {
  font-size: 1.25rem !important;
}

.fs-3 {
  font-size: 1.125rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 0.875rem !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #1e74fd !important;
}

.text-secondary {
  color: #673bb7 !important;
}

.text-success {
  color: #10d876 !important;
}

.text-info {
  color: #2754e6 !important;
}

.text-warning {
  color: #fe9431 !important;
}

.text-danger {
  color: #e50202 !important;
}

.text-light {
  color: #f7f7f7 !important;
}

.text-dark {
  color: #343a40 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #515184 !important;
}

.text-muted {
  color: #aeaed5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #1e74fd !important;
}

.bg-secondary {
  background-color: #673bb7 !important;
}

.bg-success {
  background-color: #10d876 !important;
}

.bg-info {
  background-color: #2754e6 !important;
}

.bg-warning {
  background-color: #fe9431 !important;
}

.bg-danger {
  background-color: #e50202 !important;
}

.bg-light {
  background-color: #f7f7f7 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.rounded-6 {
  border-radius: 5px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-50 {
  border-radius: 50px !important;
}

.rounded-75 {
  border-radius: 75px !important;
}

.rounded-100 {
  border-radius: 100px !important;
}

.rounded-125 {
  border-radius: 125px !important;
}

.rounded-150 {
  border-radius: 150px !important;
}

.rounded-175 {
  border-radius: 175px !important;
}

.rounded-200 {
  border-radius: 200px !important;
}

.rounded-225 {
  border-radius: 225px !important;
}

.rounded-250 {
  border-radius: 250px !important;
}

.w-5 {
  width: 5px !important;
}

.w-10 {
  width: 10px !important;
}

.w-12 {
  width: 12px !important;
}

.w-15 {
  width: 15px !important;
}

.w-20 {
  width: 20px !important;
}

.w-26 {
  width: 25px !important;
}

.w-30 {
  width: 30px !important;
}

.w-35 {
  width: 35px !important;
}

.w-40 {
  width: 40px !important;
}

.w-45 {
  width: 45px !important;
}

.w-51 {
  width: 50px !important;
}

.w-55 {
  width: 55px !important;
}

.w-60 {
  width: 60px !important;
}

.w-65 {
  width: 65px !important;
}

.w-70 {
  width: 70px !important;
}

.w-76 {
  width: 75px !important;
}

.w-80 {
  width: 80px !important;
}

.w-90 {
  width: 90px !important;
}

.w-110 {
  width: 100px !important;
}

.w-125 {
  width: 125px !important;
}

.w-150 {
  width: 150px !important;
}

.w-175 {
  width: 175px !important;
}

.w-200 {
  width: 200px !important;
}

.w-225 {
  width: 225px !important;
}

.w-250 {
  width: 250px !important;
}

.w-275 {
  width: 275px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-370 {
  width: 370px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px !important;
}

.h-5 {
  height: 5px !important;
}

.h-10 {
  height: 10px !important;
}

.h-12 {
  height: 12px !important;
}

.h-15 {
  height: 15px !important;
}

.h-20 {
  height: 20px !important;
}

.h-26 {
  height: 25px !important;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-40 {
  height: 40px !important;
}

.h-45 {
  height: 45px !important;
}

.h-51 {
  height: 50px !important;
}

.h-55 {
  height: 55px !important;
}

.h-60 {
  height: 60px !important;
}

.h-65 {
  height: 65px !important;
}

.h-70 {
  height: 70px !important;
}

.h-76 {
  height: 75px !important;
}

.h-80 {
  height: 80px !important;
}

.h-90 {
  height: 90px !important;
}

.h-110 {
  height: 100px !important;
}

.h-125 {
  height: 125px !important;
}

.h-150 {
  height: 150px !important;
}

.h-175 {
  height: 175px !important;
}

.h-200 {
  height: 200px !important;
}

.h-225 {
  height: 225px !important;
}

.h-250 {
  height: 250px !important;
}

.h-275 {
  height: 275px !important;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-370 {
  height: 370px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-600 {
  height: 600px !important;
}

.pt-6 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-225 {
  padding-top: 225px !important;
}

.pt-250 {
  padding-top: 250px !important;
}

.pb-6 {
  padding-botttom: 5px !important;
}

.pb-10 {
  padding-botttom: 10px !important;
}

.pb-20 {
  padding-botttom: 20px !important;
}

.pb-25 {
  padding-botttom: 25px !important;
}

.pb-50 {
  padding-botttom: 50px !important;
}

.pb-75 {
  padding-botttom: 75px !important;
}

.pb-100 {
  padding-botttom: 100px !important;
}

.pb-125 {
  padding-botttom: 125px !important;
}

.pb-150 {
  padding-botttom: 150px !important;
}

.pb-175 {
  padding-botttom: 175px !important;
}

.pb-200 {
  padding-botttom: 200px !important;
}

.pb-225 {
  padding-botttom: 225px !important;
}

.pb-250 {
  padding-botttom: 250px !important;
}

.mt-6 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mt-225 {
  margin-top: 225px !important;
}

.mt-250 {
  margin-top: 250px !important;
}

.mb-6 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mb-225 {
  margin-bottom: 225px !important;
}

.mb-250 {
  margin-bottom: 250px !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.lh-14 {
  line-height: 14px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-26 {
  line-height: 26px !important;
}

.lh-28 {
  line-height: 28px !important;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.lh-34 {
  line-height: 34px !important;
}

.lh-36 {
  line-height: 36px !important;
}

.lh-38 {
  line-height: 38px !important;
}

.left-0 {
  left: 0px !important;
}

.left-10 {
  left: 10px !important;
}

.left-15 {
  left: 15px !important;
}

.left-20 {
  left: 20px !important;
}

.left-25 {
  left: 25px !important;
}

.left-50 {
  left: 50px !important;
}

.left-75 {
  left: 75px !important;
}

.left-100 {
  left: 100px !important;
}

.right-0 {
  right: 0px !important;
}

.right-10 {
  right: 10px !important;
}

.right-15 {
  right: 15px !important;
}

.right-20 {
  right: 20px !important;
}

.right-25 {
  right: 25px !important;
}

.right-50 {
  right: 50px !important;
}

.right-75 {
  right: 75px !important;
}

.right-100 {
  right: 100px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.75;
}

body.fixed {
  overflow: hidden;
  position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: #1e74fd;
}

b,
strong,
.strong {
  font-weight: 700;
}

.h-0 {
  height: 0;
}

.main-wrap,
.main-wrapper {
  opacity: 1;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.main-wrap.show,
.main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

.text-grey-100 {
  color: #f8f9fa !important;
}

.text-grey-200 {
  color: #e9ecef !important;
}

.text-grey-300 {
  color: #dee2e6 !important;
}

.text-grey-400 {
  color: #ced4da !important;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.text-grey-600 {
  color: #6c757d !important;
}

.text-grey-700 {
  color: #495057 !important;
}

.text-grey-800 {
  color: #343a40 !important;
}

.text-grey-900 {
  color: #212529 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.swatch-primary {
  background-color: color-yiq(#1e74fd);
}

.swatch-secondary {
  background-color: color-yiq(#673bb7);
}

.swatch-success {
  background-color: color-yiq(#10d876);
}

.swatch-info {
  background-color: color-yiq(#2754e6);
}

.swatch-warning {
  background-color: color-yiq(#fe9431);
}

.swatch-danger {
  background-color: color-yiq(#e50202);
}

.swatch-light {
  background-color: color-yiq(#f7f7f7);
}

.swatch-dark {
  background-color: color-yiq(#343a40);
}

.accordion {
  margin-bottom: 50px;
}

.accordion .card {
  box-shadow: none;
  background: #fff;
  margin-bottom: 25px;
  border: 0;
}

.accordion .card .card-body,
.accordion .card .card-header {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 0 solid #000;
}

@media only screen and (max-width: 768px) {

  .accordion .card .card-body,
  .accordion .card .card-header {
    padding: 15px 40px 15px 15px;
  }
}

.accordion .card button {
  font-size: 14px;
  color: #444;
  font-weight: 600;
  padding-left: 0;
}

.accordion .card button:focus,
.accordion .card button:hover {
  text-decoration: none;
}

.accordion .card button.small-text {
  font-size: 16px;
}

.accordion .card button.small-text a {
  color: #111;
}

.accordion .card button i {
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 32px;
  margin-right: 10px;
  color: #333;
  position: absolute;
  left: 33px;
}

.accordion .card button span {
  font-size: 11px;
  color: #999;
  font-weight: 400;
  display: block;
}

.accordion .card button:after {
  font-family: 'themify';
  content: '';
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 23px;
  color: #888;
  transform: rotate(223deg);
  transition: all 0.5s ease;
}

@media only screen and (max-width: 768px) {
  .accordion .card button:after {
    right: 10px;
  }
}

.accordion .card button.small-text:after {
  top: 33px;
}

.accordion .card button.collapsed:after {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.accordion .card p {
  font-size: 13px;
  line-height: 32px;
  color: #a1a1a1;
  font-weight: 500;
}

.nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #444;
  border-radius: 20px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: var(--theme-color);
  color: #fff;
  border-radius: 20px;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-backdrop-none .modal-backdrop.show {
  opacity: 0 !important;
}

.modal .modal-content .close {
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 1;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  display: inline-block;
  line-height: 10px;
}

.modal .modal-content .close i {
  color: #fff;
  font-size: 16px;
}

.modal.in .modal-dialog {
  transform: none;
}

.modal.in {
  opacity: 1 !important;
}

.modal-backdrop.in {
  opacity: 0.5;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -280px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -280px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.bottom.fade .modal-dialog {
  bottom: -50%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog,
.modal.left.fade.show .modal-dialog {
  left: 0;
}

.modal.bottom.fade.in .modal-dialog,
.modal.bottom.fade.show .modal-dialog {
  bottom: 0px;
}

.modal.right.fade.in .modal-dialog,
.modal.right.fade.show .modal-dialog {
  right: 0px;
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-scrollable {
  overflow-y: auto !important;
}

.modal-backdrop.show {
  opacity: 0.6 !important;
}

.modal .dropdown-menu {
  position: relative !important;
  transform: none !important;
  border: 0;
}

.modal ul>li>a {
  font-size: 13px !important;
  color: #6c757d !important;
  line-height: 40px !important;
  font-weight: 500 !important;
  transition: all 0.4s ease;
  margin-bottom: 0px !important;
  padding: 0 !important;
}

.modal ul>li>a i {
  color: #adb5bd !important;
  font-size: 16px;
  margin-left: auto;
  margin-right: 3px;
  margin-top: 10px;
}

.modal ul>li>a:hover {
  background-color: #e9ecef;
  padding-left: 10px !important;
}

.modal ul>li>a.show i {
  transform: rotate(90deg);
  margin-right: 16px;
  margin-top: 4px;
}

.modal .number .minus,
.modal .number .plus {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
}

.modal .number input {
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin-bottom: 0;
}

.modal .cart-count-lg .number .minus,
.modal .cart-count-lg .number .plus {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 20px;
}

.modal .cart-count-lg .number input {
  height: 35px;
  width: 35px;
  line-height: 35px;
  margin-bottom: 0;
}

.modal .btn {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
  line-height: 36px;
}

.modal label {
  font-size: 13px;
  font-weight: 600;
  color: #444;
  margin-bottom: 5px;
}

.modal input {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #444;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.modal.right .modal-content::-webkit-scrollbar {
  width: 5px;
}

.modal.right .modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.modal.right .modal-content::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 1px solid transparent;
}

@media (min-width: 800px) {
  .modal-dialog-centered {
    max-width: 800px;
  }
}

@media (min-width: 576px) {
  .modal.wide.fade .modal-dialog {
    max-width: 800px;
  }

  .modal.wide-xl.fade .modal-dialog {
    max-width: 1000px;
  }

  .modal.side.fade .modal-dialog {
    max-width: 380px;
  }

  .modal-dialog.video-wrap {
    max-width: 900px;
  }

  .modal-dialog.video-wrap .close {
    right: -20px;
    top: -20px;
  }
}

.form-group.icon-input {
  position: relative;
}

.form-group.icon-input i {
  position: absolute;
  left: 15px;
  top: 19px;
}

.form-group.icon-input input {
  padding-left: 35px;
}

.form-group.icon-right-input {
  position: relative;
}

.form-group.icon-right-input i {
  position: absolute;
  right: 15px;
  top: 12px;
}

.form-group.icon-right-input input {
  padding-left: 35px;
}

.form-group .style1-input {
  height: 55px;
  line-height: 55px;
  display: block;
  font-size: 12px;
}

.form-group .style1-select {
  line-height: 55px;
  height: 55px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
}

.form-group .style2-select {
  line-height: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
  border: 2px #eee solid;
  border-radius: 7px;
}

.form-group .style2-input {
  height: 60px;
  line-height: 60px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}

.form-group .style2-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.form-group .style2-input i {
  position: relative;
  top: 6px;
}

.form-group .style2-textarea {
  line-height: 20px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}

.search-form {
  position: relative;
}

.search-form input {
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
}

.search-form i {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #999;
}

.searchbox-close {
  float: right;
}

.search-form-2 {
  position: relative;
}

.search-form-2 input {
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
}

.search-form-2 i {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #999;
}

.form-control {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid;
}

.form-control:focus {
  border-color: var(--theme-color);
}

.form-control.style2 {
  height: 60px;
  line-height: 60px;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file+.js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  vertical-align: middle;
  padding: 10px;
}

.input-file+.js-labelFile.btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555;
}

.input-file+.js-labelFile.btn-tertiary:hover,
.input-file+.js-labelFile.btn-tertiary:focus {
  color: #888888;
  border-color: #888888;
}

.input-file+.js-labelFile i {
  font-size: 24px;
  position: relative;
  top: 5px;
  display: inline-block;
}

.input-file+.js-labelFile i.large-icon {
  font-size: 42px;
}

.input-file+.js-labelFile.has-file i {
  display: none;
}

.subscribe-widget:after {
  background-size: 200px;
  position: absolute;
  top: 10px;
  right: -45%;
  opacity: 0.3;
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  width: 368px;
  height: 258px;
  z-index: -1;
}

.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #dedede;
}

.quantity-input {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.quantity .qtybutton.inc {
  right: 0;
}

.quantity .qtybutton.dec {
  left: 0;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
}

.custom-switch .custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: var(---theme-color);
  border: 0;
}

.custom-switch .custom-control-input:active~.custom-control-label::before {
  border: 0;
}

.custom-control-input:checked~.custom-control-label.bg-success::before {
  background-color: #10d876 !important;
  border-color: #10d876 !important;
}

.bar-container {
  position: relative;
  background-color: #eee;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 5px;
  margin-top: 7px;
  width: 100%;
  float: left;
}

.bar-percentage {
  background-color: #aaa;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 5px;
  padding: 0;
  width: 0;
  transition: all 0.4s ease;
}

select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
  border-radius: 10px;
}

.noUi-horizontal .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -3px;
  top: -3px;
  background-color: #222;
}

/* Styling;
 */
.noUi-background {
  background: #ddd;
}

.noUi-connect {
  background: #555;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin,
.noUi-target {
  border-radius: 2px;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #345dbb;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}

.custom-radio.size-sm label::before {
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: -30px;
}

.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #dedede;
}

.quantity-input {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.quantity .qtybutton.inc {
  right: 0;
}

.quantity .qtybutton.dec {
  left: 0;
}

.cart-wrapper .quantity {
  height: 45px;
}

.cart-wrapper .quantity-input {
  line-height: 45px;
  font-size: 15px;
}

.cart-wrapper .quantity .qtybutton {
  height: 43px;
  line-height: 43px;
}

.chart-container .percent {
  position: absolute;
  top: 22%;
  width: 100%;
  text-align: center;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.35rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.85rem;
  height: 1.675rem;
  opacity: 0;
}

.custom-control-label::before {
  position: absolute;
  top: -0.175rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  pointer-events: none;
  content: '';
  background-color: #ddd;
  border: #fff solid 1px;
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: 0px;
  border-radius: 50%;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
  position: absolute;
  top: -0.175rem;
  left: -2.35rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  content: '';
  background: no-repeat 50%/50% 50%;
}

.emoji-wrap {
  position: absolute;
  bottom: 70px;
  width: 80%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  width: auto;
  background-color: #fff;
  padding: 12px 20px 14px 24px;
  border-radius: 50px;
  display: none;
}

@media (max-width: 991.98px) {
  .emoji-wrap {
    padding: 4px 10px 13px;
  }
}

.emoji-wrap.active {
  display: block;
}

.emoji-wrap li {
  margin-right: 5px !important;
}

.emoji-wrap li i {
  margin-right: 0 !important;
  font-size: 22px;
}

@media (max-width: 991.98px) {
  .emoji-wrap li i {
    font-size: 20px;
  }
}

.vjs-poster {
  background-size: cover !important;
}

.video-js .vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 70px !important;
  height: 70px !important;
  border-radius: 70px !important;
  line-height: 70px !important;
  border: 0 !important;
  margin-left: -35px;
  margin-top: -35px;
}

.video-js.vjs-fill {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden !important;
}

.video-js.vjs-fill video {
  height: 120% !important;
  width: 116% !important;
  left: -8% !important;
  top: -5% !important;
}

.notification-box li a {
  padding-left: 70px !important;
  position: relative;
}

.notification-box li a h6,
.notification-box li a .h6 {
  float: left;
}

.notification-box li a img,
.notification-box li a .btn-round-md {
  position: absolute;
  left: 15px;
  top: 15px;
}

.notification-box li a .notification-react {
  position: absolute;
  top: 40px;
  left: 45px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  line-height: 20px;
  text-align: center;
}

.filter-wrap ul {
  padding-left: 20px;
}

.filter-wrap ul li {
  list-style-type: disc;
}

.filter-wrap ul li a {
  font-size: 12px;
  line-height: 32px;
}

.filter-wrap .tag {
  background-color: #000;
}

[type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type='file']+label {
  background: var(--theme-color);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 15px 40px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}

.table tbody td {
  border-top: 0;
  vertical-align: middle;
  padding-top: 10px;
}

.table tr:last-child td {
  border-bottom: 0 solid #000 !important;
}

.page-item .page-link {
  padding: 0 !important;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  font-weight: 600;
  color: #444;
}

.page-item .page-link:hover {
  color: #444 !important;
}

.page-item.active .page-link {
  background-color: var(--theme-color) !important;
  border: 0 !important;
}

.page-item.disabled .page-link {
  background-color: #f5f5f5 !important;
}

.form-select {
  border: 0 !important;
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #444 !important;
  width: 150px !important;
}

.breadcrumb-link li {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  color: #999;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-right: 3px !important;
}

.breadcrumb-link li:not(:last-child):after {
  content: '';
  position: relative;
  font-family: 'feather' !important;
  margin-top: 0px;
  line-height: 18px;
  padding-left: 2px;
}

.breadcrumb-link li a {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  color: #999;
}

.breadcrumb-link li a:hover {
  text-decoration: underline;
}

.cart-wrapper .table tbody td {
  border-top: 0;
  vertical-align: middle;
  padding-top: 10px;
}

.input-code {
  width: 200px;
  height: 60px;
  line-height: 60px;
}

@media (max-width: 767.98px) {
  .input-code {
    width: 165px;
  }
}

.number .minus,
.number .plus {
  width: 35px;
  height: 35px;
  background: #f2f2f2;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border: 0;
  cursor: pointer;
}

.number input {
  height: 35px;
  width: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.product-card.product-wrap {
  background: var(--theme-color-rgb);
  padding: 50px 0;
}

.product-card .tag-weight {
  padding: 4px 10px;
  background-color: #f5f5f5;
  display: inline-block;
  border-radius: 20px;
  width: auto;
  font-size: 11px;
  font-weight: 600;
  color: #777;
  letter-spacing: 0;
}

.product-card .tag-stock {
  padding: 7px 10px;
  display: inline-block;
  width: auto;
  border: 1px solid var(--theme-color);
  background: var(--theme-color-rgb);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--theme-color);
  border-radius: 5px;
}

.product-card .tag-stock b {
  font-size: 12px;
}

.product-card .btn-cart {
  width: auto;
  padding: 13px 27px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.product-card .number .minus,
.product-card .number .plus,
.product-card .number .cart-input {
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.product-card .option-tag {
  border: 3px #eee solid;
  position: relative;
}

.product-card .option-tag h4,
.product-card .option-tag .h4 {
  padding: 0 10px;
  background-color: #fff;
  z-index: 1;
  top: -10px;
  margin-bottom: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  width: auto;
  position: absolute;
  color: #999;
}

.product-card .option-size a {
  transition: all 0.4s ease;
}

.product-card .option-size a.active {
  background: var(--theme-color) !important;
  color: #fff !important;
}

.product-card .option-size a:hover,
.product-card .option-size a:focus {
  background: var(--theme-color) !important;
  color: #fff !important;
}

.star {
  float: left;
}

.star img {
  float: left;
  margin-right: 4px;
}

.menu-category {
  padding-left: 18px;
  padding-top: 10px;
}

.menu-category li {
  padding-left: 0px;
  list-style-type: disc;
}

.menu-category li a {
  font-size: 11px;
  font-weight: 600;
  color: #555;
}

.side-wrap .form-check {
  display: flex;
  margin-bottom: 5px;
}

.side-wrap .form-check-label {
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  line-height: 24px;
  cursor: pointer;
}

.side-wrap .form-check-label:hover {
  color: var(--theme-color);
}

.side-wrap .tag-name {
  padding: 5px 12px;
  border-radius: 20px;
  border: 2px #999 solid;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #555;
}

.checkout-form .btn {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 600;
  line-height: 36px;
}

.checkout-form label {
  font-size: 13px;
  font-weight: 600;
  color: #444;
  margin-bottom: 5px;
}

.checkout-form input {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #444;
}

.product-wrap-six .card {
  /* width: 20%; */
  float: left;
  position: relative;
  border-right: 1px solid #e1e1f0 !important;
}

.product-wrap-six .card:nth-child(n + 6) {
  border-top: 1px solid #e1e1f0 !important;
}

.product-wrap-six .card:nth-child(5n) {
  border-right: 1px solid #fff !important;
}

.product-wrap-bottom .card:last-child {
  border-bottom: 1px solid #f7f7f7 !important;
}

.product-filter-three .col:last-child {
  display: none;
}

.animation-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

/* Fading exits */
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.hover-zoom:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}

.hover-zoom-image:hover img {
  transition: all 0.3s ease;
  transform: scale(1.1);
}

.scroll-bar {
  margin-left: 0px;
  overflow-y: scroll !important;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

@media (min-width: 991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }

  .middle-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1800px) {
  .col-xxl-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xxl-10 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .col-xxl-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .col-xxl-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 1600px) {
  .w-xl-90 {
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
  }

  .vh-lg-100 {
    height: 100vh;
  }
}

@media (max-width: 1600px) {
  .main-content .middle-sidebar-bottom .sidebar-right {
    visibility: visible;
    opacity: 1;
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-left {
    width: 100%;
  }

  .right-chat {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .main-content {
    padding-right: 0;
  }

  .filter-wrap .col {
    width: 17%;
    max-width: 17%;
  }

  .filter-wrap .col:last-child {
    width: 32%;
    max-width: 32%;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 74%;
  }
}

@media (max-width: 1400px) {

  .overflow-visible-xl,
  .overflow-visible-xl .owl-stage-outer {
    overflow: visible !important;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 92%;
  }

  .demo-style h1,
  .demo-style .h1 {
    font-size: 36px !important;
  }

  .demo-style .demo-banner:after {
    background-size: 120% !important;
    background-position: top;
  }
}

@media (max-width: 1200px) {
  .filter-wrap .col {
    width: 25%;
    max-width: 25%;
  }

  .filter-wrap .col:last-child {
    width: 100%;
    max-width: 100%;
    flex: auto;
    margin-top: 15px;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 120%;
  }

  .demo-style .demo-banner:after {
    background-size: 120% !important;
    background-position: top left -120px;
  }

  .demo-banner {
    height: 75vh !important;
  }
}

@media (max-width: 991px) {
  .d-block-md {
    display: block !important;
  }

  .md-mt-6 {
    margin-top: 3.2rem;
  }

  .md-mb-3 {
    margin-bottom: 1rem !important;
  }

  .demo-style .demo-banner:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .filter-wrap .col {
    width: 50%;
    max-width: 50%;
    flex: auto;
    margin-top: 15px;
  }

  .sm-mb-3 {
    margin-bottom: 1rem !important;
  }

  .sm-mt-6 {
    margin-top: 3.2rem;
  }

  .d-block-sm {
    display: block !important;
  }

  .h-md-250 {
    max-height: 250px;
    overflow: hidden;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #e1e1f0 !important;
  }

  .display1-sm-size {
    font-size: 28px !important;
  }

  .listing-wrapper .col-lg-3.col-md-4.col-xs-6,
  .listing-wrapper .col-lg-4.col-md-4.col-xs-6 {
    border: 0 !important;
  }
}

@media (max-width: 749px) {
  .sm-mt-3 {
    margin-top: 1rem;
  }

  .sm-p-0 {
    padding: 0 !important;
  }

  .xs-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xs-mb-3 {
    margin-bottom: 1rem;
  }

  .xs-mb-4 {
    margin-bottom: 2rem;
  }

  .xs-mt-6 {
    margin-top: 4rem;
  }

  .d-xs-block {
    display: block !important;
  }

  .border-none-xs {
    border: 0 !important;
  }

  .login-card {
    max-width: 360px !important;
    min-width: 360px !important;
  }

  .slick-dots {
    bottom: 14px;
    right: 20px;
    width: auto;
  }

  .slick-dots li {
    width: 12px;
    height: 12px;
  }

  .slick-dots li button:before {
    width: 8px;
    height: 8px;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xs-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xs-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xs-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .border-bottom-xs {
    border-bottom: 1px solid #e1e1f0 !important;
  }
}

@media (max-width: 360px) {
  .login-card {
    max-width: 320px !important;
    min-width: 320px !important;
  }
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  display: none;
  height: 40px;
}

@media only screen and (max-width: 992px) {
  .navbar-toggler {
    display: inline-block;
  }
}

.navbar-toggler-icon {
  width: 27px;
  height: 2px;
  background-color: #999;
  position: relative;
  top: -2px;
}

.navbar-toggler-icon:after {
  content: '';
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  top: -8px;
  left: 0;
}

.navbar-toggler-icon:before {
  content: '';
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.icon-count {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -10px;
  right: -10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.upper-header .dropdown-menu {
  min-width: 120px !important;
  position: absolute !important;
  top: 20px;
  margin-top: 5px;
}

.upper-header .dropdown-menu .dropdown-item {
  color: #333 !important;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
}

.upper-header .dropdown-menu .dropdown-item:focus {
  color: #333 !important;
}

.upper-header .navbar-nav {
  flex-direction: row;
}

.upper-header a {
  font-size: 11px;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 13px;
  line-height: 26px;
  color: #777;
}

.upper-header .nav-item {
  padding-left: 5px;
}

.upper-header .nav-item .dropdown-menu.show {
  position: absolute !important;
}

.upper-header .nav-item a {
  display: inline-block;
  float: right;
}

.upper-header .nav-item a.pe-0 {
  padding-right: 0 !important;
}

.upper-header .nav-item a span {
  position: absolute;
  font-size: 8px;
  color: #fff;
  display: inline-block;
  padding: 6px;
  height: auto;
  line-height: 1;
  border-radius: 8px;
  font-weight: 500;
  top: -10px;
  right: 20%;
}

.upper-header .nav-item a span:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #e50202;
}

.upper-header .nav-item a.dropdown-toggle:after {
  border: 0 !important;
  font-family: 'themify';
  content: '';
  color: #555;
  font-size: 8px !important;
  padding-left: 5px;
  position: relative;
  top: 2px;
  right: 0;
}

.upper-header.bg-green,
.upper-header.bg-invert {
  border-bottom: 1px solid #339728;
}

.upper-header.bg-green .nav-link,
.upper-header.bg-green p,
.upper-header.bg-green a,
.upper-header.bg-invert .nav-link,
.upper-header.bg-invert p,
.upper-header.bg-invert a {
  color: #fff !important;
}

.upper-header .dropdown-toggle-white:after {
  color: #fff !important;
}

.header-wrapper {
  z-index: 1001;
}

.header-wrapper.bg-green .nav-icon i {
  color: #fff !important;
}

.header-wrapper .logo {
  width: 145px;
}

.header-wrapper .form-group input {
  border: 0;
  background-color: #f6f6f6;
  border-radius: 7px;
  width: 350px;
  height: 45px;
}

.header-wrapper .form-group i {
  z-index: 2;
  top: 12px;
}

.header-wrapper .location {
  padding: 7px 0px 7px 0px;
  position: relative;
  width: auto;
  display: flex;
}

.header-wrapper .location i {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 20px;
  line-height: 30px;
}

.header-wrapper .location span,
.header-wrapper .location h4,
.header-wrapper .location .h4 {
  line-height: 30px;
}

.header-wrapper .nav-icon {
  padding: 10px 8px 0 8px;
  margin-left: 3px;
  position: relative;
}

.header-wrapper .nav-icon i {
  font-size: 26px;
}

.header-wrapper .dot-count {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}

.lower-header {
  z-index: 1000;
  position: relative;
}

.lower-header.bg-invert .nav-item .nav-link.dropdown-toggle:after,
.lower-header.bg-invert .nav-item .nav-link,
.lower-header.bg-invert .dropdown-item,
.lower-header.bg-invert .dropdown-toggle,
.lower-header.bg-invert .dropdown-toggle i {
  color: #fff;
}

.lower-header .dropdown-toggle-white:after {
  color: #fff !important;
  right: 15px !important;
}

.lower-header .dropdown-toggle {
  line-height: 38px;
  width: 100%;
  display: block;
  font-size: 12px;
  text-align: left;
  padding-left: 0px;
  font-weight: 600;
  color: #555;
}

.lower-header .dropdown-toggle i {
  margin-right: 10px;
  font-size: 22px;
  color: #999;
  position: relative;
  top: 5px;
}

.lower-header .dropdown-toggle:after {
  right: 0px;
  position: absolute;
  top: 8px;
  border: 0 !important;
  font-family: 'themify';
  content: '';
  color: #999;
  font-size: 10px !important;
}

.lower-header .dropdown-menu li .dropdown-submenu {
  position: absolute;
  top: -9999px;
  left: 100%;
  margin: 0;
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  visibility: hidden;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

.lower-header .dropdown-menu li .dropdown-submenu li {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 2.7rem 1rem 0;
  position: relative;
  line-height: 1;
}

.lower-header .dropdown-menu li .dropdown-submenu li .menu-banner {
  height: calc(100% + 2.7rem);
  margin: -2.7rem -1rem 0;
  overflow: hidden;
}

.lower-header .dropdown-menu li .dropdown-submenu li:not(:last-child) {
  padding: 2.8rem 2rem 0 0rem;
}

.lower-header .dropdown-item {
  line-height: 30px;
  font-size: 13px;
  font-weight: 500 !important;
  color: #222 !important;
  position: relative;
  transition: all 0.4s ease;
}

.lower-header .dropdown-item:active,
.lower-header .dropdown-item:focus,
.lower-header .dropdown-item:hover {
  color: var(--theme-color);
  background-color: transparent;
}

.lower-header .dropdown-item span {
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.lower-header .nav-item .dropdown-menu.show {
  position: absolute;
}

.lower-header .nav-item .nav-link,
.lower-header .nav-item .nav-items {
  padding: 0 20px 0 3px !important;
  line-height: 50px;
  font-size: 13px;
  font-weight: 600;
  color: #555;
}

.lower-header .nav-item .nav-link.pe-0,
.lower-header .nav-item .nav-items.pe-0 {
  padding-right: 0 !important;
}

.lower-header .nav-item .nav-link span,
.lower-header .nav-item .nav-items span {
  position: absolute;
  font-size: 8px;
  color: #fff;
  display: inline-block;
  padding: 6px;
  height: auto;
  line-height: 1;
  border-radius: 8px;
  font-weight: 500;
  top: -10px;
  right: 20%;
}

.lower-header .nav-item .nav-link span:after,
.lower-header .nav-item .nav-items span:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 6px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #e50202;
}

.lower-header .nav-item .nav-link.dropdown-toggle:after,
.lower-header .nav-item .nav-items.dropdown-toggle:after {
  border: 0 !important;
  font-family: 'themify';
  content: '';
  color: #555;
  font-size: 8px !important;
  padding-left: 5px;
  position: relative;
  top: 2px;
  right: 0;
}

.lower-header .dropdown-right .dropdown-item {
  position: relative;
}

.lower-header .dropdown-right .dropdown-item.dropdown-toggle:after {
  border: 0 !important;
  font-family: 'feather' !important;
  content: '';
  color: #999;
  font-size: 13px !important;
  top: 5px;
}

#menumodal .nav-link {
  font-size: 14px !important;
  line-height: 50px !important;
  font-weight: 600 !important;
}

#menumodal .nav-link span {
  display: none;
}

#menumodal .nav-link.dropdown-toggle:after {
  border: 0 !important;
  font-family: 'feather' !important;
  content: '';
  color: #999;
  font-size: 13px !important;
  top: 2px;
  right: 3px;
  position: absolute;
}

#menumodal .dropdown-menu {
  box-shadow: none !important;
  padding-left: 20px !important;
}

#menumodal .dropdown-menu .dropdown-item {
  font-size: 14px !important;
  line-height: 50px !important;
}

.category-card.style-one {
  padding: 25px;
  border-radius: 20px;
  border: 2px solid var(--theme-color);
  position: relative;
}

.category-card.style-one:after {
  background-color: var(--theme-color);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.03;
  border-radius: 20px;
  z-index: -1;
}

.category-card.style-one img {
  width: 70px !important;
  margin-bottom: 10px;
}

.category-card.style-one.active {
  background-color: var(--theme-color);
}

.category-card.style-one.active h4,
.category-card.style-one.active .h4 {
  color: #fff !important;
}

.category-card.style-two {
  padding: 0px 30px 0px 75px;
  border-radius: 30px;
}

.category-card.style-two img {
  width: 50px;
  display: inline-block;
  margin-right: 15px;
  position: absolute;
  left: 15px;
  top: 5px;
}

.category-card.style-two h4,
.category-card.style-two .h4 {
  line-height: 60px;
  margin-bottom: 0;
}

.category-card.style-three .image-round {
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
}

.category-card.style-three .image-round.after-none:after {
  display: none;
}

.category-card.style-three .image-round:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: var(--theme-color);
  opacity: 0.08;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.category-card.style-three.active .image-round {
  border: 2px solid var(--theme-color);
}

.item-card .card-image {
  position: relative;
  margin-bottom: 10px;
}

.item-card .card-image img {
  position: relative;
  z-index: 2;
  padding: 10px;
}

.item-card .card-image:after {
  background: var(--theme-color);
  opacity: 0.08;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
  z-index: 1;
  border-radius: 100px 0 100px 0;
}

.dropdown-menu {
  z-index: 10;
}

.dropdown-menu li .sub-menu {
  position: absolute;
  width: 100%;
  left: 95%;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 20px;
  padding-top: 1.25rem;
  list-style: none;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-top: 0;
  border-bottom-right-radius: var(--size-border-radius);
  white-space: nowrap;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.25s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.25s cubic-bezier(0.28, 0.12, 0.22, 1);
}

.dropdown-menu li .sub-menu li a {
  line-height: 30px;
  font-size: 13px;
  font-weight: 500 !important;
  color: #222 !important;
  position: relative;
  transition: all 0.4s ease;
}

.dropdown-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.navbar {
  position: static;
}

.navbar .navbar-nav .mega-menu,
.navbar .navbar-nav .site-menu {
  position: relative;
}

.navbar .navbar-nav .mega-menu .sub-menu,
.navbar .navbar-nav .site-menu .sub-menu {
  position: absolute;
  min-width: 180px;
  margin: 0;
  list-style: none;
  left: -0.9375rem;
  top: 100%;
  -webkit-box-shadow: 0px 5px 10px rgba(62, 68, 90, 0.1);
  box-shadow: 0px 5px 10px rgba(62, 68, 90, 0.1);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  will-change: transform, opacity;
  -webkit-transition: all 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  transition: all 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  background-color: #fff;
  border: 1px solid #edeef5;
  padding: 10px 5px;
  z-index: 10000 !important;
}

.navbar .navbar-nav .mega-menu:hover .sub-menu,
.navbar .navbar-nav .site-menu:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.navbar .navbar-nav .mega-menu {
  white-space: nowrap;
  position: static;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .navbar-nav .mega-menu .sub-menu {
  align-items: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  width: 100%;
  left: 0;
  margin-top: -1px;
  border-top-color: transparent !important;
  padding-top: 1.875rem;
  padding-bottom: 2.25rem;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 25px;
  border: 0;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
}

.navbar .navbar-nav .mega-menu .sub-menu li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .navbar-nav .mega-menu .sub-menu li a {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  line-height: 40px;
  font-weight: 600;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.navbar .navbar-nav .mega-menu .sub-menu li .sub-mega-menu {
  position: relative;
  width: 100%;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0 !important;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  padding-top: 0.625rem;
  min-width: 12.5rem;
  margin: 0;
  list-style: none;
}

.navbar .navbar-nav .mega-menu .sub-menu li .sub-mega-menu li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .navbar-nav .mega-menu .sub-menu li .sub-mega-menu li a {
  font-weight: 500;
  color: #555;
}

.product-filter-three .col-lg-4.pe-0 {
  display: none;
}

.header-menu-mob {
  top: 0;
}

.table-responsive table {
  min-width: 580px;
}

.page-nav {
  padding-top: 80px;
  padding-bottom: 80px;
  background-position: top -50px center;
  background-size: cover;
}

@media only screen and (max-width: 576px) {
  .page-nav {
    padding-top: 50px;
    padding-bottom: 40px;
    background-size: 200%;
    background-position: top -50px center;
  }
}

.page-nav h1,
.page-nav .h1 {
  font-size: 42px;
  color: #222;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

@media only screen and (max-width: 576px) {

  .page-nav h1,
  .page-nav .h1 {
    font-size: 32px;
    line-height: 1.2;
  }
}

.page-nav span {
  font-size: 12px;
  color: #666;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .das-nav .h-250 {
    height: 200px !important;
  }
}

.upper-footer {
  background-color: #f9f9f9;
}

.upper-footer.bg-black {
  background-color: #111 !important;
}

.upper-footer.bg-black h3,
.upper-footer.bg-black .h3,
.upper-footer.bg-black h4,
.upper-footer.bg-black .h4 {
  color: #fff;
}

.upper-footer.bg-black ul li a {
  color: #999;
}

@media only screen and (max-width: 576px) {
  .upper-footer .mt-5 {
    margin-top: 0 !important;
  }
}

.upper-footer hr {
  margin: 0;
  border-top: 1px solid #aaa;
}

.upper-footer h3,
.upper-footer .h3 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #202435;
  line-height: 24px;
}

.upper-footer ul {
  margin-top: 20px;
}

.upper-footer ul li a {
  color: #71778e;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  display: block;
}

.lower-footer-bg {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

@media only screen and (max-width: 992px) {
  .lower-footer-bg {
    height: 150px;
  }
}

@media only screen and (max-width: 576px) {
  .lower-footer-bg {
    height: 75px;
  }
}

.banner-wrap .style1 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 576px) {
  .banner-wrap .style1 .slide-content.w-50 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .banner-wrap .style1 {
    padding: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .banner-wrap .style1 {
    padding: 60px 35px;
  }
}

.banner-wrap .style1 span {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.banner-wrap .style1 h2,
.banner-wrap .style1 .h2 {
  font-size: 40px;
  font-weight: 400;
  color: #333;
  line-height: 50px;
  display: inline-block;
}

@media only screen and (max-width: 768px) {

  .banner-wrap .style1 h2,
  .banner-wrap .style1 .h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

.banner-wrap .style1 p {
  color: #999;
  font-size: 13px;
  font-weight: 500;
}

.banner-wrap .style1 .btn {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 25px;
}

.banner-wrap .style2 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 576px) {

  .banner-wrap .style2 .slide-content.w-50,
  .banner-wrap .style2 .slide-content.w-75 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .banner-wrap .style2 {
    padding: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .banner-wrap .style2 {
    padding: 60px 35px;
  }
}

.banner-wrap .style2 span {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.banner-wrap .style2 h2,
.banner-wrap .style2 .h2 {
  font-size: 32px;
  font-weight: 300;
  color: #333;
  line-height: 40px;
  display: inline-block;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {

  .banner-wrap .style2 h2,
  .banner-wrap .style2 .h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .banner-wrap .style2 h2 b,
  .banner-wrap .style2 .h2 b {
    display: inline-block !important;
  }
}

.banner-wrap .style2 p {
  color: #999;
  font-size: 13px;
  font-weight: 500;
}

.banner-wrap .style2 .btn {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 25px;
}

.banner-wrap .style3 {
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 576px) {

  .banner-wrap .style3 .slide-content.w-50,
  .banner-wrap .style3 .slide-content.w-75 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .banner-wrap .style3 {
    padding: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .banner-wrap .style3 {
    padding: 60px 35px;
  }
}

.banner-wrap .style3 span {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.banner-wrap .style3 h2,
.banner-wrap .style3 .h2 {
  font-size: 32px;
  font-weight: 300;
  color: #333;
  line-height: 40px;
  margin-bottom: 15px;
  display: inline-block;
}

@media only screen and (max-width: 768px) {

  .banner-wrap .style3 h2,
  .banner-wrap .style3 .h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

.banner-wrap .style3 p {
  color: #999;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
}

.banner-wrap .style3 .btn {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 25px;
}

.banner-wrap .style4 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.banner-wrap .style4 span {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.banner-wrap .style4 h2,
.banner-wrap .style4 .h2 {
  font-size: 24px;
  font-weight: 300;
  color: #333;
  line-height: 32px;
  display: inline-block;
  text-transform: uppercase;
}

.banner-wrap .style4 p {
  color: #999;
  font-size: 13px;
  font-weight: 500;
}

.banner-wrap .style4 .btn {
  font-weight: 600;
  color: #fff;
  padding: 8px 30px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 25px;
}

.owl-arrow-none .owl-nav {
  display: none !important;
}

.owl-dots-none .owl-dots {
  display: none !important;
}

.owl-arrow-top .owl-nav {
  position: absolute;
  width: auto;
  right: 0;
  top: -50px;
  margin-top: 0;
}

.owl-arrow-top .owl-nav i {
  font-size: 25px;
  color: #999 !important;
}

.owl-arrow-top .owl-nav button {
  margin: 2px !important;
}

.owl-arrow-top .owl-nav button:hover {
  background-color: transparent !important;
}

.owl-arrow-center.arrow-container .owl-nav {
  margin-top: -20px;
}

.owl-arrow-center.arrow-container .owl-nav i {
  background-color: #fff;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
  font-size: 20px;
}

.owl-arrow-center.arrow-container .owl-nav .owl-prev {
  left: 10px;
}

.owl-arrow-center.arrow-container .owl-nav .owl-next {
  right: 10px;
}

.owl-arrow-center .owl-nav {
  position: absolute;
  width: 100%;
  right: 0;
  top: 50%;
  margin-top: -10px;
}

.owl-arrow-center .owl-nav i {
  font-size: 28px;
  color: #666 !important;
}

.owl-arrow-center .owl-nav button {
  margin: 2px !important;
}

.owl-arrow-center .owl-nav button:hover {
  background-color: transparent !important;
}

.owl-arrow-center .owl-nav .owl-prev {
  left: 8px;
  position: absolute;
}

.owl-arrow-center .owl-nav .owl-next {
  right: 8px;
  position: absolute;
}

.owl-arrow-center .owl-dots {
  position: absolute;
  width: 100%;
  z-index: 5;
  bottom: 10px;
}

.owl-arrow-center .owl-dots .owl-dot.active span {
  background: var(--theme-color) !important;
}

.owl-arrow-center .owl-dots span {
  margin: 4px !important;
}

.owl-center .owl-stage-outer {
  overflow: visible;
}

.owl-center .owl-item .card {
  transition: all 0.4s ease;
}

.owl-center .owl-item.item-before .card {
  transform: perspective(500px) rotateY(50deg);
}

.owl-center .owl-item.item-after .card {
  transform: perspective(500px) rotateY(-50deg);
}

.owl-center .owl-item.center {
  z-index: 3;
  position: relative;
}

.owl-center .owl-item.center .card {
  transform: scale(1.4);
  z-index: 2;
}

.owl-center .owl-dots {
  bottom: -30px;
}

.owl-carousel-visible .owl-stage-outer {
  overflow: visible !important;
}

@media (min-width: 991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }

  .middle-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1800px) {
  .col-xxl-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xxl-10 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .col-xxl-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .col-xxl-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 1600px) {
  .w-xl-90 {
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
  }

  .vh-lg-100 {
    height: 100vh;
  }
}

@media (max-width: 1600px) {
  .main-content .middle-sidebar-bottom .sidebar-right {
    visibility: visible;
    opacity: 1;
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-left {
    width: 100%;
  }

  .right-chat {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .main-content {
    padding-right: 0;
  }

  .filter-wrap .col {
    width: 17%;
    max-width: 17%;
  }

  .filter-wrap .col:last-child {
    width: 32%;
    max-width: 32%;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 74%;
  }
}

@media (max-width: 1400px) {

  .overflow-visible-xl,
  .overflow-visible-xl .owl-stage-outer {
    overflow: visible !important;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 92%;
  }

  .demo-style h1,
  .demo-style .h1 {
    font-size: 36px !important;
  }

  .demo-style .demo-banner:after {
    background-size: 120% !important;
    background-position: top;
  }
}

@media (max-width: 1200px) {
  .filter-wrap .col {
    width: 25%;
    max-width: 25%;
  }

  .filter-wrap .col:last-child {
    width: 100%;
    max-width: 100%;
    flex: auto;
    margin-top: 15px;
  }

  .banner-wrap .embed-responsive-16by9::before {
    padding-top: 120%;
  }

  .demo-style .demo-banner:after {
    background-size: 120% !important;
    background-position: top left -120px;
  }

  .demo-banner {
    height: 75vh !important;
  }
}

@media (max-width: 991px) {
  .d-block-md {
    display: block !important;
  }

  .md-mt-6 {
    margin-top: 3.2rem;
  }

  .md-mb-3 {
    margin-bottom: 1rem !important;
  }

  .demo-style .demo-banner:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .filter-wrap .col {
    width: 50%;
    max-width: 50%;
    flex: auto;
    margin-top: 15px;
  }

  .sm-mb-3 {
    margin-bottom: 1rem !important;
  }

  .sm-mt-6 {
    margin-top: 3.2rem;
  }

  .d-block-sm {
    display: block !important;
  }

  .h-md-250 {
    max-height: 250px;
    overflow: hidden;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #e1e1f0 !important;
  }

  .display1-sm-size {
    font-size: 28px !important;
  }

  .listing-wrapper .col-lg-3.col-md-4.col-xs-6,
  .listing-wrapper .col-lg-4.col-md-4.col-xs-6 {
    border: 0 !important;
  }
}

@media (max-width: 749px) {
  .sm-mt-3 {
    margin-top: 1rem;
  }

  .sm-p-0 {
    padding: 0 !important;
  }

  .xs-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xs-mb-3 {
    margin-bottom: 1rem;
  }

  .xs-mb-4 {
    margin-bottom: 2rem;
  }

  .xs-mt-6 {
    margin-top: 4rem;
  }

  .d-xs-block {
    display: block !important;
  }

  .border-none-xs {
    border: 0 !important;
  }

  .login-card {
    max-width: 360px !important;
    min-width: 360px !important;
  }

  .slick-dots {
    bottom: 14px;
    right: 20px;
    width: auto;
  }

  .slick-dots li {
    width: 12px;
    height: 12px;
  }

  .slick-dots li button:before {
    width: 8px;
    height: 8px;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xs-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xs-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xs-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .border-bottom-xs {
    border-bottom: 1px solid #e1e1f0 !important;
  }
}

@media (max-width: 360px) {
  .login-card {
    max-width: 320px !important;
    min-width: 320px !important;
  }
}

.contact-wrap {
  z-index: 1;
  top: -100px;
  padding: 60px 80px 70px 80px;
}

@media (max-width: 991.98px) {
  .contact-wrap {
    padding: 50px;
  }
}

@media (max-width: 767.98px) {
  .contact-wrap {
    padding: 40px 30px;
  }

  .contact-wrap .display3-size {
    font-size: 40px !important;
    margin-bottom: 30px !important;
  }
}

.contact-wrapper {
  padding: 80px;
  max-width: 550px;
}

@media (max-width: 991.98px) {
  .contact-wrapper {
    padding: 50px;
    max-width: 500px;
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

.login-card {
  min-width: 380px;
  max-width: 400px;
}

.blog-single .post-title {
  margin-top: 10px;
  font-size: 34px;
  padding: 0 12%;
  line-height: 1.3;
  display: block;
}

@media only screen and (max-width: 768px) {
  .blog-single .post-title {
    font-size: 24px;
    padding: 0 5%;
  }
}

@media only screen and (max-width: 576px) {
  .blog-single .post-title {
    font-size: 22px;
    padding: 0;
    line-height: 1.3;
  }
}

.blog-single .drop-cap span {
  font-size: 70px;
  color: #000;
  font-weight: 700;
  float: left;
  line-height: 64px;
  margin-right: 10px;
}

.blog-single .blog-text {
  font-size: 14px;
  font-weight: 500;
  color: #6c757d;
  line-height: 32px;
}

.blog-single .next-article {
  padding-top: 30px;
  padding-bottom: 30px;
}

.blog-single .next-article h2 a,
.blog-single .next-article .h2 a {
  font-size: 32px;
  color: #333;
  font-weight: 700;
  line-height: 1.2;
}

.blog-single .next-article h6,
.blog-single .next-article .h6 {
  font-size: 11px;
}

.blog-single .next-article:before {
  display: block !important;
  content: '';
  width: 100%;
  height: 2px;
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right,
      #fff 0,
      #eeeeee 35%,
      #eeeeee 65%,
      #fff 100%);
}

.blog-single .next-article:after {
  display: block !important;
  content: '';
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right,
      #fff 0,
      #eeeeee 35%,
      #eeeeee 65%,
      #fff 100%);
}

@media (max-width: 767.98px) {
  .blog-single .next-article {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.blog-wrapper article {
  margin-bottom: 20px;
}

.blog-wrapper article h2 a,
.blog-wrapper article .h2 a {
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
}

.demo-style .container-fluid,
.demo-style .container-sm,
.demo-style .container-md,
.demo-style .container-lg,
.demo-style .container-xl {
  max-width: 1600px;
}

.demo-style .demo-header {
  background-color: #163a0f;
  z-index: 10;
}

@media (max-width: 991.98px) {
  .demo-style .demo-header {
    position: relative;
  }
}

.demo-style .demo-header .logo {
  width: 125px;
}

@media (max-width: 991.98px) {
  .demo-style .demo-header .logo {
    width: 100px;
  }
}

.demo-style .demo-banner {
  height: 95vh;
  background-color: #163a0f;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 991.98px) {
  .demo-style .demo-banner {
    height: auto !important;
    padding-bottom: 50px;
    margin-bottom: 40px;
  }
}

.demo-style .demo-banner .container {
  z-index: 4;
  position: relative;
}

.demo-style .demo-banner:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
}

.demo-style .demo-banner .content-text {
  padding-top: 120px;
}

@media (max-width: 991.98px) {
  .demo-style .demo-banner .content-text {
    padding-top: 60px;
  }
}

.demo-style .navbar-nav {
  text-align: center;
  width: 100%;
  display: block;
  padding-top: 8px;
}

.demo-style .navbar-nav li {
  list-style-type: none;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 767.98px) {
  .demo-style .navbar-nav li {
    width: 100%;
    text-align: left;
  }
}

.demo-style .navbar-nav li a {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
}

.demo-style h1,
.demo-style .h1 {
  font-size: 45px;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 1199.98px) {

  .demo-style h1,
  .demo-style .h1 {
    font-size: 32px !important;
    line-height: 1.2;
  }
}

.demo-style h2,
.demo-style .h2 {
  font-size: 36px;
  letter-spacing: 0;
  line-height: 1.3;
  color: green;
}

@media (max-width: 767.98px) {

  .demo-style h2,
  .demo-style .h2 {
    font-size: 24px;
    line-height: 1.2;
  }
}

.demo-style h5,
.demo-style .h5 {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.3;
  font-weight: 700;
}

@media (max-width: 767.98px) {

  .demo-style h5,
  .demo-style .h5 {
    font-size: 16px;
    line-height: 1.2;
  }
}

.demo-style p {
  font-size: 14px;
  line-height: 26px;
  margin-top: 10px;
}

@media (max-width: 1199.98px) {
  .demo-style p {
    line-height: 20px;
    font-size: 12px;
  }
}

.demo-style .nav-tabs .nav-link {
  border: 2px green solid;
  color: green;
  font-size: 13px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 25px;
  padding: 10px 30px;
}

.demo-style .nav-tabs .nav-link.active {
  background-color: green;
  color: #fff;
}

@media (max-width: 575.98px) {
  .demo-style .nav-tabs .nav-link {
    font-size: 13px;
    padding: 5px 0px;
    border: 0;
    border-radius: 0;
  }

  .demo-style .nav-tabs .nav-link.active {
    background-color: transparent;
    color: #000;
    border-bottom: 2px solid #000;
  }
}

.demo-style .demo-item {
  overflow: hidden;
}

.demo-style .demo-item img {
  transition: all 0.4s ease;
  position: relative;
  box-shadow: 0px 24px 74px 10px rgba(0, 0, 0, 0.05);
  margin-top: 0;
  margin-bottom: 10px;
}

.demo-style .demo-item:hover img {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.demo-style .demo-item:hover .btn-wrap {
  top: 45%;
}

.demo-style .demo-item .btn-wrap {
  top: -100px;
  transition: all 0.4s ease;
  width: 100%;
  left: 0;
  position: absolute;
  text-align: center;
}

.demo-style .demo-item .btn-wrap a {
  width: 150px;
  background-color: #222;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 50px;
  height: 50px;
  display: inline-block;
  margin: 4px;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

/*# sourceMappingURL=style.css.map */
.slick-dots {
  bottom: 10px !important;
}

.slick-dots li {
  margin: 0 1px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: green !important;
}

.slick-slider .slick-prev {
  left: 12px !important;
  z-index: 1;
}

.slick-slider .slick-prev:before {
  padding: 8px;
  border-radius: 3px;
  background-color: #fff;
  color: #000 !important;
  content: '\e64a' !important;
  font-family: 'themify' !important;
  opacity: 1 !important;
}

.slick-slider .slick-next {
  right: 25px !important;
  z-index: 1;
}

.slick-slider .slick-next:before {
  padding: 8px;
  border-radius: 3px;
  background-color: #fff;
  color: #000 !important;
  content: '\e649' !important;
  font-family: 'themify' !important;
}

.slick-arrow-top .slick-prev {
  top: -35px;
  right: 25px !important;
  left: auto !important;
}

.slick-arrow-top .slick-prev:before {
  content: '\e65e' !important;
  color: #999 !important;
  padding: 0 !important;
}

.slick-arrow-top .slick-next {
  top: -35px;
  right: 0 !important;
}

.slick-arrow-top .slick-next:before {
  content: '\e65d' !important;
  color: #999 !important;
  padding: 0 !important;
}

.slick-dot-bottom .slick-dots {
  bottom: -15px !important;
}

.product-filter-three .col-lg-4.pe-0 {
  display: none;
}

.header-menu-mob {
  top: 0;
}

.table-responsive table {
  min-width: 580px;
}

.z-index-2 {
  z-index: 2 !important;
}

.upper-header.bg-green .nav-item a.dropdown-toggle:after {
  color: #fff !important;
}

.modal-backdrop {
  display: none;
  transition: all 0.4s ease;
}

.modal-backdrop.active {
  display: block;
  opacity: 0.6;
}

.modal.show {
  display: block;
}

/* COSTOM CSS INFOCLIQ */

.line-limt-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-limt-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.category-listing .slick-slide img {
  display: block;
  height: 80px;
  object-fit: cover;
}

.footer .btn-round-md {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.category-listing a,
.sub-category-slider a {
  cursor: pointer;
}

.border-bottom-nor {
  border-bottom: 1px solid #e1e1e1 !important;
}