////////////////////////////////////////////////////
//---------------- C O L O R ----------------------
///////////////////////////////////////////////////


//to ignore cli warning about deprecated mixin
// $enable-deprecation-messages: false;
// $ignore-warning             : true;


// stylelint-disable-next-line scss/dollar-variable-default
$primary   : #1E74FD !default;
$secondary : #673BB7 !default;
$success   : #10d876 !default;
$info      : #2754e6 !default;
$warning   : #FE9431 !default;
$danger    : #E50202 !default;
$light     : #f7f7f7 !default;
$dark      : #343a40;

// Body
$body-bg   : #fff;
$body-color: #515184;
$enable-negative-margins: true;


////////////////////////////////////////////////////
//---------------- SIZE VARIABLE ----------------------
///////////////////////////////////////////////////


$size-px: (5:  5px,10: 10px,12: 12px,15: 15px,20: 20px,26: 25px,30: 30px,35: 35px,40: 40px,45: 45px,51: 50px,55: 55px,60: 60px,65: 65px,70: 70px,76: 75px,80: 80px,90: 90px,110: 100px,125: 125px,150: 150px,175: 175px,200: 200px,225: 225px,250: 250px,275: 275px,300: 300px,350: 350px,370: 370px,400: 400px,450: 450px,500: 500px,600: 600px, ) !default;
$length-px: (6: 5px,10: 10px,20: 20px,25: 25px,50: 50px,75: 75px,100: 100px,125: 125px,150: 150px,175: 175px,200: 200px,225: 225px,250: 250px, ) !default;
$lineheight-px: (14: 14px,18: 18px,20: 20px,22: 22px,24: 24px,26: 26px,28: 28px,30: 30px,32: 32px,34: 34px,36: 36px,38: 38px, ) !default;
$opacity: (0: 0,25: .25,50: .5,75: .75,100: 1, ) !default;
$space-px: (0: 0px,10: 10px,15: 15px,20: 20px,25: 25px,50: 50px,75: 75px,100: 100px,) !default;


////////////////////////////////////////////////////
//---------------- F O N T S ----------------------
///////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');



$headings-font-weight   : 400;

$font-size-base         : 1rem;
$font-weight-base       : 400 !default;


$h1-font-size         : 24px;
$h2-font-size         : 20px;
$h3-font-size         : 18px;
$h4-font-size         : 16px;
$h5-font-size         : 14px;
$h6-font-size         : 12px;


$display1-size  			: 36px;
$display2-size  			: 44px;
$display3-size  			: 50px;
$display4-size  			: 60px;
$display5-size        : 80px;

$font-xssssss         : 8px;
$font-xsssss          : 10px;
$font-xssss           : 12px;
$font-xsss            : 14px;
$font-xss             : 16px;
$font-xs              : 18px;
$font-sm              : 20px;
$font-md              : 22px;
$font-lg              : 25px;
$font-xl              : 28px;
$font-xxl             : 32px;

$text-muted : #AEAED5;
$colors     : () !default;

$heading-primary-size: 1.8rem;
$sub-heading-size    : 1.6rem !default;
$grid-gutter-width   : 30px;
$dropdown-lik-color  : $body-color;
$border-color        : #E1E1F0;
$headings-color      :#111 !default;
$grid-breakpoints    : (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400);
$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1100px) !default;

$input-btn-focus-width: 0rem !default;
$border-radius-base   : 4px !default;

$shadow-color: rgba(160, 159, 202, 0.15);



////////////////////////////////////////////////////
//---------------- NAVIGATION ----------------------
///////////////////////////////////////////////////
$nav-padding:20px;
$nav-width:280px;
$nav-logo-height:90px;
$nav-menu-link-margin:1px 17px; 
$nav-menu-link-size:15px;
$nav-menu-link-color:#fff;
$nav-menu-link-icon-size:24px;
$chat-width:290px; 

$nav-footer-icon-padding:6px 20px;
$nav-footer-icon-size:25px;


////////////////////////////////////////////////////
//---------------- LEFT SIDEBAR ----------------------
///////////////////////////////////////////////////
$left-sidebar-width:270px;
$right-sidebar-header-height:96px;

////////////////////////////////////////////////////
//---------------- PAGE NAV ----------------------
///////////////////////////////////////////////////


$list-group-bg:transparent;


////////////////////////////////////////////////////
//---------------- HEADER ----------------------
///////////////////////////////////////////////////


////////////////////////////////////////////////////
//---------------- FOOTER ----------------------
///////////////////////////////////////////////////

$app-footer-height:56px;
$app-footer-bg:#fff;
$app-footer-border-top:#fff;

////////////////////////////////////////////////////
//---------------- CUSTOM SWITCH ----------------------
///////////////////////////////////////////////////

$custom-control-indicator-size :1.85rem;
$custom-control-indicator-border-width:1px;
$custom-control-indicator-border-color:#fff;
$custom-control-indicator-bg:#ddd;
$custom-control-indicator-checked-disabled-bg:#fff;
$custom-control-indicator-checked-border-color:var(--theme-color);
$custom-control-indicator-checked-bg:var(--theme-color);
$custom-control-radio-small-size:20px;
 


// CUSTOMIZE DROPDOWN PANEL
$dropdown-menu-width:300px;
$setting-color-width:32px;
$setting-toggle-width:20px; 





$form-check-input-width: 1em;

$pagination-margin-start: 5px;

$accordion-button-padding-y: 1.3rem;