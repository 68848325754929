.banner-wrap {
    .style1 {
        padding-top: 120px;
        padding-bottom: 120px;
        .slide-content.w-50 {
            @include respond("sm") {
                width: 100% !important;
            }
        }
        @include respond("md") {
            padding: 50px;
        }
        @include respond("sm") {
            padding: 60px 35px;
        }
        span {
            font-size: 11px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        h2 {
            font-size: 40px;
            font-weight: 400;
            color: #333;
            line-height: 50px;
            display: inline-block;
            @include respond("md") {
                font-size: 28px;
                line-height: 34px;
            }
        }
        p {
            color: #999;
            font-size: 13px; 
            font-weight: 500;
        }
        .btn {
            font-size: 12px; 
            font-weight: 600;
            color: #fff;
            padding: 12px 30px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 25px;

        }

    }
    .style2 {
        padding-top: 50px;
        padding-bottom: 50px;
        .slide-content.w-50,.slide-content.w-75 {
            @include respond("sm") {
                width: 100% !important;
            }
        }
        @include respond("md") {
            padding: 50px;
        }
        @include respond("sm") {
            padding: 60px 35px;
        }
        span {
            font-size: 10px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        h2 {
            font-size: 32px;
            font-weight: 300;
            color: #333;
            line-height: 40px;
            display: inline-block;
            text-transform: uppercase;
            @include respond("md") {
                font-size: 22px;
                line-height: 32px;
                b {
                    display: inline-block !important;
                }
            }
        }
        p {
            color: #999;
            font-size: 13px; 
            font-weight: 500;
        }
        .btn {
            font-size: 12px; 
            font-weight: 600;
            color: #fff;
            padding: 12px 30px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 25px;

        }
    }
    .style3 {
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 50px;
        padding-right: 50px;
        .slide-content.w-50,.slide-content.w-75 {
            @include respond("sm") {
                width: 100% !important;
            }
        }
        @include respond("md") {
            padding: 50px;
        }
        @include respond("sm") {
            padding: 60px 35px;
        }
        span {
            font-size: 10px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        h2 {
            font-size: 32px;
            font-weight: 300;
            color: #333;
            line-height: 40px;
            margin-bottom: 15px;
            display: inline-block; 
            @include respond("md") {
                font-size: 28px;
                line-height: 34px;
            }
        }
        p {
            color: #999;
            font-size: 13px; 
            font-weight: 500;
            margin-bottom: 20px;
        }
        .btn {
            font-size: 12px; 
            font-weight: 600;
            color: #fff;
            padding: 12px 30px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 25px;

        }
    }
    .style4 {
        padding-top: 40px;
        padding-bottom: 40px;
        span {
            font-size: 10px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        h2 {
            font-size: 24px;
            font-weight: 300;
            color: #333;
            line-height: 32px;
            display: inline-block;
            text-transform: uppercase
        }
        p {
            color: #999;
            font-size: 13px; 
            font-weight: 500;
        }
        .btn {
            font-weight: 600;
            color: #fff;
            padding:8px 30px;
            font-weight: 600;
            font-size: 11px;
            letter-spacing: 1px;
            line-height: 25px;

        }
    }
}
 

.owl-arrow-none {
    .owl-nav {
        display: none !important;
    }
}

.owl-dots-none {
    .owl-dots {
        display: none !important;
    }
}

.owl-arrow-top {
    .owl-nav {
        position: absolute;
        width: auto;
        right:0;
        top:-50px;
        margin-top: 0;
        i{
            font-size: 25px;
            color: #999 !important;
        }
        button {
            margin:2px !important;
            &:hover {
                background-color:transparent !important;
            }
        }
    }
}
.owl-arrow-center {
    &.arrow-container {
        .owl-nav {
            margin-top: -20px;
            i {
                background-color: #fff;
                padding: 7px;
                border-radius: 5px;
                box-shadow: 0 8px 30px rgba(0, 0, 0 , 5%) !important;
                font-size: 20px;
            }
            .owl-prev {left:10px;}
            .owl-next {right:10px;}
        }
    }
    .owl-nav {
        position: absolute;
        width: 100%;
        right:0;
        top:50%;
        margin-top: -10px;
        i{
            font-size: 28px;
            color: #666 !important;
        }
        button {
            margin:2px !important;
            &:hover {
                background-color:transparent !important;
            }
        }
        .owl-prev {
            left:8px;
            position: absolute;
        }
        .owl-next {
            right:8px;
            position: absolute;
        }
    }
    .owl-dots {
        position: absolute;
        width: 100%;
        z-index: 5;
        bottom:10px;
        .owl-dot {
            &.active span {background:var(--theme-color) !important;}    
        }
        span {
            margin:4px !important;
        }
    }
}
.owl-center {
    .owl-stage-outer {
        overflow: visible;
    }
    .owl-item {
        .card{ transition: all 0.4s ease;}
        &.item-before {
            .card { transform: perspective(500px) rotateY(50deg);}
        }
        &.item-after {
            .card { transform: perspective(500px) rotateY(-50deg);}
        }
        &.center {
            z-index: 3;position: relative;
            .card{ transform: scale(1.4);z-index: 2;  }
        }
    }
    .owl-dots {
        bottom: -30px;
    }
}
.owl-carousel-visible {
    .owl-stage-outer {
        overflow: visible !important;
    }
}

.slick-dots {bottom:10px !important;}
.slick-dots li {margin:0 1px !important;}
.slick-dots li button:before {font-size: 12px !important; color:green !important;}

.slick-slider .slick-prev {left:12px !important; z-index: 1;}
.slick-slider .slick-prev:before { padding:8px; border-radius: 3px; background-color: #fff; color:#000 !important; content: "\e64a" !important;  font-family: "themify" !important; opacity: 1 !important;}
.slick-slider .slick-next {right:25px !important; z-index: 1;}
.slick-slider .slick-next:before { padding:8px; border-radius: 3px; background-color: #fff; color:#000 !important; content: "\e649" !important; font-family: "themify" !important; }

.slick-arrow-top .slick-prev {top:-35px; right:25px !important; left:auto !important;}
.slick-arrow-top .slick-prev:before {content: "\e65e" !important; color:#999 !important; padding:0 !important; }

.slick-arrow-top .slick-next {top:-35px; right:0 !important;}
.slick-arrow-top .slick-next:before {content: "\e65d" !important; color:#999 !important; padding:0 !important; }
.slick-dot-bottom .slick-dots {bottom:-15px !important; }





