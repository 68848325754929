.modal-backdrop.show {opacity: 0.9;}


.modal-dialog {

}
.modal-backdrop-none {
    .modal-backdrop.show {opacity: 0 !important; }
}

.modal .modal-content {
    .close {
        border:0;
        position: absolute;
        right: 10px;
        top:10px;
        opacity: 1;
        z-index: 2;
        width: 24px;
        height: 24px;
        border-radius:20px;
        display: inline-block;
        line-height: 10px; 
        i {
            color: #fff;
            font-size: 16px; 
        }
    }
}

.modal-dialog {
    .modal.in & {
        transform: $modal-show-transform;
    }
    
}
.modal.in {
    opacity: 1 !important;
}

.modal-backdrop {
    &.in { opacity: $modal-backdrop-opacity; }
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -280px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
            transition: opacity 0.3s linear, left 0.3s ease-out;
}
/*Right*/
.modal.right.fade .modal-dialog{
    right: -280px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}
// bottom
.modal.bottom.fade .modal-dialog{
    bottom: -50%;
    -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
         -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
            transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog,
.modal.left.fade.show .modal-dialog{
    left: 0;
}
.modal.bottom.fade.in .modal-dialog,
.modal.bottom.fade.show .modal-dialog{
    bottom: 0px;
}
.modal.right.fade.in .modal-dialog,
.modal.right.fade.show .modal-dialog{
    right: 0px;
}

.modal-open .modal {overflow-y: hidden;}

.modal-scrollable {
    overflow-y: auto !important;
}
.modal-backdrop {
    &.show {
        opacity: 0.6 !important;
    }
}
.modal {
    .dropdown-menu {
        position: relative !important;
        transform: none !important;
        border:0;
    }
    ul > li > a {
        font-size: 13px !important;
        color: #6c757d !important;
        line-height: 40px !important;
        font-weight: 500 !important;
        transition: all 0.4s ease;
        margin-bottom: 0px !important;
        padding: 0 !important;
        i {
            color: #adb5bd !important;
            font-size: 16px; 
            margin-left: auto;
            margin-right: 3px;
            margin-top: 10px;
        }
        &:hover {
            background-color: #e9ecef;
            padding-left: 10px !important;
        }
        &.show i {
            transform: rotate(90deg);
            margin-right: 16px;
            margin-top: 4px;
        }
    } 
    .number {
        .minus, .plus{
            width: 30px;
            height: 30px;
            font-size: 16px; 
            line-height: 30px; 
        }
        input {
            height: 30px;
            width: 30px; 
            line-height: 30px;
            margin-bottom: 0;
        }
    }
    .cart-count-lg {
        .number {
            .minus, .plus{
                width: 35px;
                height: 35px;
                line-height: 35px;
                font-size: 20px; 
            }
            input {
                height: 35px;
                width: 35px; 
                line-height: 35px;
                margin-bottom: 0;
            }
        }
    }   
    .btn {
        font-size: 11px; 
        text-transform: uppercase;
        letter-spacing: 0.8px; 
        font-weight: 600;
        line-height: 36px;
    }
    label {
        font-size: 13px ;
        font-weight: 600;
        color: #444;
        margin-bottom: 5px;
    }
    input {
        margin-bottom: 10px;
        font-size: 12px; 
        font-weight: 600; 
        color: #444;
    }

}


.modal.left .modal-dialog,
.modal.right .modal-dialog {

    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border:0;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
            transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
    left: 0;
}
    
/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

.modal.right .modal-content::-webkit-scrollbar {
  width: 5px;
}
 
.modal.right .modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
 
.modal.right .modal-content::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 1px solid transparent;
}

@media (min-width: 800px) {
    .modal-dialog-centered {
        max-width: 800px;
    }
    .modal-sm {
        max-width: 300px;
    }
    
}
@media (min-width: 576px) {
    .modal.wide.fade .modal-dialog {
        max-width: 800px;
    }
    .modal.wide-xl.fade .modal-dialog {
        max-width: 1000px;
    }    

    .modal.side.fade .modal-dialog {
        max-width: 380px;
    }
    .modal-dialog {
        &.video-wrap {
            max-width: 900px;
            .close {
                right:-20px; 
                top:-20px;  
            }
        }
    }

}

.modal .btn-primary:hover {
    background-color: transparent;
}

.table > :not(:first-child) {
    border-top: 0 solid #000;
}



